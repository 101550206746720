import * as React from "react"
import { Link } from "gatsby"
import NewsTeaserImage from "./paragraphs/news-teaser-image"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NewsTeaser({ post }) {
	const { node } = post
  const { created, field_teaser_text, relationships, path, title } = node
  const pathAlias = `${path.alias}/`
  const { field_news_category, field_teaser_image } = relationships
  const image = typeof field_teaser_image !== "undefined" ? field_teaser_image : null
  const category = field_news_category.name
  const tid = field_news_category.drupal_internal__tid

  let categoryColour = `bg-green-100 text-green-800`
  if (tid === 3) {
    categoryColour = `bg-green-100 text-green-800`
  } else if (tid === 4) {
    categoryColour = `bg-green-100 text-green-800`
  }

	return (
		<div key={title}>
      <div aria-label={title} className="u-faux-block-link">
        <NewsTeaserImage image={image} />
        <div className="py-8">
          <div>
            <span
              className={classNames(
                categoryColour,
                'inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium'
              )}
            >
              {category}
            </span>
          </div>
            <p className="text-xl mt-2 font-semibold tracking-tight text-sky-900">{title}</p>
            <p className="mt-3 text-base text-gray-500">{field_teaser_text}</p>
          <p className="mt-3 text-sm text-black">
            {created}
          </p>
          <p className="mt-3 text-base font-semibold text-sky-700 hover:text-sky-650">
            Read full story
          </p>
            <Link to={pathAlias} className="u-faux-block-link__overlay">{title}</Link>
        </div>
      </div>
    </div>
	)
}