import * as React from "react"
import {
  GatsbyImage, 
  getImage,
  StaticImage,
} from "gatsby-plugin-image"

export default function NewsTeaserImage({ image }) {
  const isDefaultImage = image === null ? true: false
  let alt = `DEC Housing`,
      imageData = null
  if (!isDefaultImage) {
    alt = image.field_media_image.alt
    imageData = getImage(image?.relationships?.field_media_image?.localFile)
  }
	return (
		<div className="relative -mx-4 lg:ml-0 lg:-mr-0 lg:mt-0" aria-hidden="true">
      <div className="mx-auto">
        {isDefaultImage && <StaticImage className="object-cover w-full h-0 pb-[75%]" src="../../images/default-news-sml.jpg" alt={alt} />}
        {!isDefaultImage && <GatsbyImage className="w-full object-cover h-0 pb-[75%]" image={imageData} alt={alt} />}
      </div>
    </div>
	)
}